import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { CDBBtn } from "cdbreact";
import { useSelector } from "react-redux";
import { selectEmployees } from "src/stores/employeeSlice";
import { selectUser } from "src/stores/userSlice";
import { toast } from "react-toastify";
import "../css/LeaveTabSA.css";
import "../css/commonStyles.css";
import axios from "axios";
import ms from "ms";
import moment from "moment";
import ApiClient from "../ApiClient";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Card from "../components/Card";
import { Modal } from "reactstrap";

const useStyles = makeStyles({
  root: {
    "& .modal-content": {
      backgroundColor: "#ffffff05",
      border: "none",
    },
    "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows":
      {
        fontFamily: "Montserrat",
      },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontFamily: "Montserrat",
      fontWeight: "bold",
    },
  },
  alignCenter: {
    textAlign: "center",
  },
  red: {
    color: "red",
    fontWeight: "400",
    fontSize: "0.875rem",
  },
  modal: {
    marginTop: "45vh",
  },
  modalBody: {
    overflow: "auto",
    minHeight: "30vh",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

function LeaveTabSA() {
  const classes = useStyles();
  const employees = useSelector(selectEmployees);
  const user = useSelector(selectUser);
  const [leaves, setLeaves] = useState([]);
  const [leavesBalance, setLeavesBalance] = useState([]);
  const [newLeaves, setNewLeaves] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsBalance, setColumnsBalance] = useState([]);
  const [newLeaveCols, setNewLeaveCols] = useState([]);
  const [minDate1, setMinDate1] = useState(null);
  const [minDate2, setMinDate2] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [officeholidays, setOfficeHolidays] = useState([]);
  const [disableLeave, setDisableLeave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balanceT, setBalanceT] = useState({
    empName: "",
    startDate: "",
    endDate: "",
  });
  const initialState = {
    empName1: "",
    startDate: "",
    endDate: "",
    noOfDays: "",
    leaveType: "",
    empName2: "",
    startDt: "",
    endDt: "",
  };
  const [state, setState] = useState(initialState);
  const leaveOptions = [
    { value: "Personal Leave", label: "Personal Leave" },
    { value: "Sick/Medical Leave", label: "Sick/Medical Leave" },
    { value: "Emergency Leave", label: "Emergency Leave" },
    { value: "Maternity Leave", label: "Maternity Leave" },
    { value: "Paternity Leave", label: "Paternity Leave" },
    { value: "Bereavement Leave", label: "Bereavement Leave" },
  ];

  console.log(user);

  function handleChange(evt) {
    const data = evt.target.value;
    if (evt.target.name === "noOfDays") {
      let { value, min, max } = evt.target;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      setState({
        ...state,
        [evt.target.name]: value,
      });
    } else {
      setState({
        ...state,
        [evt.target.name]: data,
      });
    }
  }

  function handleBalanceChange(evt) {
    const data = evt.target.value;
    setBalanceT({
      ...balanceT,
      [evt.target.name]: data,
    });
  }

  const acceptLeaveReq = async (row) => {
    setLoading(true);
    try {
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/action/leavereqAccept/?id=${row.REQUEST_ID}`,
        { params: { name: user?.name } }
      );
      fetchNewLeaves();
      setLoading(false);
      toast.success("Leave accepted");
    } catch (error) {
      console.log(error);
      let errmsg = error?.response
        ? error?.response?.data?.message
          ? error?.response?.data?.message
          : `${error?.response?.status} - ${error?.response?.statusText}`
        : "Something went wrong. Please try again later";
      toast.error(errmsg);
      setLoading(false);
    }
  };

  const rejectLeaveReq = async (row) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/action/rejectLeave?id=${row.REQUEST_ID}`
      );
      fetchNewLeaves();
      toast.success("Leave rejected");
    } catch (error) {
      console.log(error);
      let errmsg = error?.response
        ? error?.response?.data?.message
          ? error?.response?.data?.message
          : `${error?.response?.status} - ${error?.response?.statusText}`
        : "Something went wrong. Please try again later";
      toast.error(errmsg);
    }
  };

  const deleteLeaveReq = async (row) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/leave/deleteLeave`,
        { data: { data: row } }
      );
      fetchNewLeaves();
      toast.success("Leave deleted");
    } catch (error) {
      console.log(error);
      let errmsg = error?.response
        ? error?.response?.data?.message
          ? error?.response?.data?.message
          : `${error?.response?.status} - ${error?.response?.statusText}`
        : "Something went wrong. Please try again later";
      toast.error(errmsg);
    }
    setLoading(false);
  };

  const fetchNewLeaves = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/newLeaveRequest`,
        { params: { mail: user?.mail } }
      );
      let leaveTableCols = [
        {
          headerName: "Name",
          field: "EMP_NAME",
          sortable: true,
          width: 200,
          renderCell: (params) => (
            <span className={!params?.row?.REQUEST_TYPE ? null : classes.red}>
              {params?.row?.EMP_NAME}
            </span>
          ),
        },
        {
          headerName: "Leave Type",
          field: "LEAVE_TYPE",
          sortable: true,
          width: 150,
          renderCell: (params) => (
            <span className={!params?.row?.REQUEST_TYPE ? null : classes.red}>
              {params?.row?.LEAVE_TYPE}
            </span>
          ),
        },
        {
          headerName: "#Days",
          field: "NO_OF_DAYS",
          sortable: true,
          width: 60,
          renderCell: (params) => (
            <span className={!params?.row?.REQUEST_TYPE ? null : classes.red}>
              {params?.row?.NO_OF_DAYS}
            </span>
          ),
        },
        {
          headerName: "Start Date",
          field: "START_DATE",
          sortable: true,
          width: 100,
          renderCell: (params) => (
            <span className={!params?.row?.REQUEST_TYPE ? null : classes.red}>
              {moment(params.row?.START_DATE).format("DD-MMM-YY")}
            </span>
          ),
        },
        {
          headerName: "End Date",
          field: "END_DATE",
          sortable: true,
          width: 100,
          renderCell: (params) => (
            <span className={!params?.row?.REQUEST_TYPE ? null : classes.red}>
              {moment(params.row?.END_DATE).format("DD-MMM-YY")}
            </span>
          ),
        },
        {
          headerName: "Leave Reason",
          field: "LEAVE_REASON",
          sortable: true,
          width: 200,
          renderCell: (params) => (
            <span className={!params?.row?.REQUEST_TYPE ? null : classes.red}>
              {params?.row?.LEAVE_REASON}
            </span>
          ),
        },
        {
          headerName: "Action",
          field: "LEAVE_ACTION",
          sortable: true,
          width: 90,
          renderCell: (params) =>
            !params?.row?.REQUEST_TYPE ? (
              <span>
                <Tooltip title="Accept">
                  <i
                    className="fa fa-check"
                    style={{
                      color: "green",
                      cursor: "pointer",
                      marginRight: "6px",
                    }}
                    onClick={() => acceptLeaveReq(params.row)}
                  ></i>
                </Tooltip>
                <Tooltip title="Reject">
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    style={{
                      color: "#d90101",
                      cursor: "pointer",
                      marginRight: "6px",
                    }}
                    onClick={() => rejectLeaveReq(params.row)}
                  ></i>
                </Tooltip>
                <Tooltip title="Delete">
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    style={{
                      color: "#4f9fcf",
                      cursor: "pointer",
                      marginRight: "6px",
                    }}
                    onClick={() => deleteLeaveReq(params.row)}
                  ></i>
                </Tooltip>
              </span>
            ) : (
              <span className="text-center">
                <Tooltip title="Reject">
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    style={{
                      color: "#d90101",
                      cursor: "pointer",
                      marginRight: "6px",
                    }}
                    onClick={() => deleteLeaveReject(params.row)}
                  ></i>
                </Tooltip>
                <Tooltip title="Delete">
                  <i
                    className="fa fa-trash"
                    style={{
                      color: "#4f9fcf",
                      cursor: "pointer",
                      marginRight: "6px",
                    }}
                    onClick={() => deleteLeaveAccept(params.row)}
                  ></i>
                </Tooltip>
              </span>
            ),
        },
        {
          headerName: "Request Type",
          field: "LEAVE_REQUEST",
          sortable: true,
          width: 130,
          renderCell: (params) => (
            <span
              className={params?.row?.REQUEST_TYPE === 0 ? null : classes.red}
            >
              {!params?.row?.REQUEST_TYPE ? "New Leave" : "Delete Leave"}
            </span>
          ),
        },
      ];

      setNewLeaveCols(leaveTableCols);
      setNewLeaves(data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLeaveAccept = async (row) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/leave/deleteLeave`,
        { data: { data: row } }
      );
      toast.success("Leave Deleted Successfully");
      fetchNewLeaves();
    } catch (error) {
      toast.error("Something went wrong. Please try again later");
    }
    setLoading(false);
  };

  const deleteLeaveReject = async (row) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/leave/rejectDeleteLeave`,
        { REQUEST_ID: row.REQUEST_ID, STATUS: "Reject" }
      );
      toast.success("Delete Request Rejected Successfully");
      fetchNewLeaves();
    } catch (error) {
      toast.error("Something went wrong. Please try again later");
    }
  };

  const getLeavesByDate = async () => {
    try {
      if (state.startDt && state.endDt) {
        if (new Date(state.startDt) <= new Date(state.endDt)) {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/admin/getLeaveRecords`,
            {
              code: state.empName2,
              startDt: state.startDt,
              endDt: state.endDt,
            }
          );
          if (data.length === 0) {
            toast.error("No matching records found");
            document.getElementById("leaveRecordsTable").style.display = "none";
          } else {
            document.getElementById("leaveRecordsTable").style.display =
              "block";
            let leaveTableCols = [
              {
                headerName: "Emp ID",
                field: "EMP_ID",
                sortable: true,
                width: 100,
              },
              {
                headerName: "Name",
                field: "EMP_NAME",
                sortable: true,
                width: 220,
              },
              {
                headerName: "Leave Type",
                field: "LEAVE_TYPE",
                sortable: true,
                width: 150,
              },
              {
                headerName: "#Days",
                field: "NO_OF_DAYS",
                sortable: true,
                width: 75,
              },
              {
                headerName: "Start Date",
                field: "START_DATE",
                sortable: true,
                width: 130,
                valueGetter: (params) => {
                  return moment(params.row?.START_DATE).format("DD-MMM-YYYY");
                },
              },
              {
                headerName: "End Date",
                field: "END_DATE",
                sortable: true,
                width: 130,
                valueGetter: (params) => {
                  return moment(params.row?.END_DATE).format("DD-MMM-YYYY");
                },
              },
              {
                headerName: "Leave Reason",
                field: "LEAVE_REASON",
                sortable: true,
                width: 200,
              },
              {
                headerName: "Status",
                field: "LEAVE_STATUS",
                sortable: true,
                width: 100,
                valueGetter: (params) => {
                  return params.row?.LEAVE_STATUS
                    ? params.row?.LEAVE_STATUS
                    : "Pending";
                },
              },
            ];
            setColumns(leaveTableCols);
            setLeaves(data);
          }
          setDisableLeave(false);
        } else toast.error("Start Date should be less than End Date");
      } else {
        setDisableLeave(false);
        toast.error("Start Date and End Date fields are mandatory");
      }
    } catch (error) {
      console.log(error);
      setDisableLeave(false);
      let errmsg = error?.response
        ? error?.response?.data?.message
          ? error?.response?.data?.message
          : `${error?.response?.status} - ${error?.response?.statusText}`
        : "Something went wrong. Please try again later";
      toast.error(errmsg);
    }
  };

  const findNoOfDays = (sDate, eDate) => {
    var startDate = new Date(sDate);
    var endDate = new Date(eDate);
    if (startDate < new Date(balanceT.startDate)) {
      startDate = new Date(balanceT.startDate);
    }
    if (endDate > new Date(balanceT.endDate)) {
      endDate = new Date(balanceT.endDate);
    }
    endDate.setDate(endDate.getDate() + 1);
    const daysOfWeek = [];
    let i = 0;
    while (startDate < endDate) {
      if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
        if (!isInArray(moment(startDate).format("YYYY-MM-DD")))
          daysOfWeek.push(startDate.getDay());
      }
      startDate.setDate(startDate.getDate() + 1);
      i++;
    }
    return daysOfWeek.length;
  };

  const isInArray = (value) => {
    return (
      (
        officeholidays.find((item) => {
          return item === value;
        }) || []
      ).length > 0
    );
  };

  const getOfficeHolidays = async () => {
    const currentYear = moment().format("YYYY");
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/leave/officeholiday`,
        {
          year: currentYear,
        }
      );
      let result = data
        .filter((el) => {
          if (
            el.LOCATION === user?.country ||
            (user?.country !== "India" && el.LOCATION != "India")
          )
            return el;
        })
        .map((el) => moment(el.DATE).format("YYYY-MM-DD"));
      setOfficeHolidays(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeavesBalanceByDate = async () => {
    try {
      if (balanceT.startDate && balanceT.endDate) {
        if (new Date(balanceT.startDate) <= new Date(balanceT.startDate)) {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/admin/getLeaveBalance`,
            {
              code: balanceT.empName,
              startDt: balanceT.startDate,
              endDt: balanceT.endDate,
            }
          );
          if (data.length === 0) {
            toast.error("No matching records found");
            document.getElementById("leaveRecordsTableBalance").style.display =
              "none";
          } else {
            document.getElementById("leaveRecordsTableBalance").style.display =
              "block";
            data?.map(
              (ele) =>
                (ele.NO_OF_DAYS = findNoOfDays(ele.START_DATE, ele.END_DATE))
            );
            let leaveTableCols = [
              {
                headerName: "Emp ID",
                field: "EMP_CODE",
                sortable: true,
                width: 100,
              },
              {
                headerName: "Name",
                field: "EMP_NAME",
                sortable: true,
                width: 220,
              },
              {
                headerName: "Accepted Leave",
                field: "AcceptedNo",
                sortable: true,
                width: 200,
              },
              {
                headerName: "Pending Leave",
                field: "PendingNO",
                sortable: true,
                width: 200,
              },
              {
                headerName: "Current Year Leave Balance",
                field: "CURRENT_YEAR_LEAVE_BALANCE",
                sortable: true,
                width: 250,
              },
              {
                headerName: "Previous Year Leave Balance",
                field: "TOTAL_LEAVE_BALANCE",
                sortable: true,
                width: 250,
              },
            ];
            setColumnsBalance(leaveTableCols);

            console.log(data);
            const result = data.reduce((acc, item) => {
              const existingItem = acc.find(
                (element) => element.EMP_CODE === item.EMP_CODE
              );
              if (existingItem) {
                if (item.LEAVE_STATUS === null) {
                  existingItem.PendingNO += parseFloat(item.NO_OF_DAYS);
                } else if (item.LEAVE_STATUS == "Accepted") {
                  existingItem.AcceptedNo += parseFloat(item.NO_OF_DAYS);
                }
              } else {
                const newItem = {
                  EMP_CODE: item.EMP_CODE,
                  EMP_NAME: item.EMP_NAME,
                  CURRENT_YEAR_LEAVE_BALANCE: item.CURRENT_YEAR_LEAVE_BALANCE,
                  TOTAL_LEAVE_BALANCE: item.TOTAL_LEAVE_BALANCE,
                  PendingNO: item.LEAVE_STATUS === null ? item.NO_OF_DAYS : 0,
                  AcceptedNo:
                    item.LEAVE_STATUS === "Accepted" ? item.NO_OF_DAYS : 0,
                };
                acc.push(newItem);
              }
              return acc;
            }, []);
            setLeavesBalance(result);
          }
          setDisableLeave(false);
        } else toast.error("Start Date should be less than End Date");
      } else {
        setDisableLeave(false);
        toast.error("Start Date and End Date fields are mandatory");
      }
    } catch (error) {
      console.log(error);
      setDisableLeave(false);
      let errmsg = error?.response
        ? error?.response?.data?.message
          ? error?.response?.data?.message
          : `${error?.response?.status} - ${error?.response?.statusText}`
        : "Something went wrong. Please try again later";
      toast.error(errmsg);
    }
  };
  const resetFilter = () => {
    setState(initialState);
    document.getElementById("leaveRecordsTable").style.display = "none";
  };

  const raiseLeave = async () => {
    if (
      state.empName1 &&
      state.startDate &&
      state.endDate &&
      state.noOfDays &&
      state.leaveType
    ) {
      const emp = employees.find((item) => item.EMP_CODE === state.empName1);
      const empName = emp.EMP_NAME;
      if (new Date(state.startDate) <= new Date(state.endDate)) {
        try {
          setIsDisabled(true);
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/admin/raiseLeave`,
            {
              empcode: state.empName1,
              empName: empName,
              startDate: state.startDate,
              endDate: state.endDate,
              days: state.noOfDays,
              leaveType: state.leaveType,
              approvedBy: user.name,
            }
          );
          toast.success("Leave added successfully");
          setState(initialState);
          setIsDisabled(false);
        } catch (error) {
          console.log(error);
          setIsDisabled(false);
          let errmsg = error?.response
            ? error?.response?.data?.message
              ? error?.response?.data?.message
              : `${error?.response?.status} - ${error?.response?.statusText}`
            : "Something went wrong. Please try again later";
          toast.error(errmsg);
        }
      } else toast.error("Start Date should be less than End Date");
    } else {
      toast.error("All fields are mandatory");
    }
  };

  const initDateRange = () => {
    const minsec = ms("0d");
    const min_date = new Date(new Date(state.startDate) - minsec);
    setMinDate1(moment(min_date).format("YYYY-MM-DD"));
  };

  const initRangeForLeave = () => {
    const minsec = ms("0d");
    const min_date = new Date(new Date(state.startDt) - minsec);
    setMinDate2(moment(min_date).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    initDateRange();
  }, [state.startDate]);

  useEffect(() => {
    initRangeForLeave();
  }, [state.startDt]);

  useEffect(() => {
    fetchNewLeaves();
  }, [user]);

  useEffect(() => {
    getOfficeHolidays();
  }, []);

  return (
    <div className="main__div">
      {user?.role !== "Manager" && (
        <Card>
          <h4>Pending Leave Requests</h4>
          <DataGrid
            className={classes.root}
            getRowId={(row) =>
              row.EMP_NAME + row.START_DATE + row.END_DATE + row.LEAVE_STATUS
            }
            {...newLeaves}
            rows={newLeaves}
            columns={newLeaveCols}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            hideFooterSelectedRowCount
          />
        </Card>
      )}

      {user?.role !== "Manager" && (
        <Card>
          <h4>Request leave on behalf of others</h4>
          <div className="row mt-2">
            <div className="col-md-4">
              <label htmlFor="empName1">
                Employee Name<span className={classes.red}>*</span>
              </label>
              <br />
              <select
                className="form-select"
                name="empName1"
                value={state.empName1}
                onChange={handleChange}
                required
              >
                <option disabled hidden></option>
                {employees &&
                  employees
                    ?.filter((el) => el.STATUS === "Active")
                    .map((emp) => {
                      return (
                        <option key={emp.EMP_CODE} value={emp.EMP_CODE}>
                          {emp.EMP_NAME}
                        </option>
                      );
                    })}
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="startDate">
                Start Date<span className={classes.red}>*</span>
              </label>
              <br />
              <input
                className="form-control"
                name="startDate"
                type="date"
                value={state.startDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="endDate">
                End Date<span className={classes.red}>*</span>
              </label>
              <br />
              <input
                className="form-control"
                name="endDate"
                type="date"
                value={state.endDate}
                onChange={handleChange}
                min={minDate1}
                required
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="noOfDays">
                Number of Days<span className={classes.red}>*</span>
              </label>
              <br />
              <input
                className="form-control"
                name="noOfDays"
                type="number"
                value={state.noOfDays}
                onChange={handleChange}
                min="1"
                max="30"
                required
              ></input>
            </div>
            <div className="col-md-4">
              <label htmlFor="leaveType">
                Leave Type<span className={classes.red}>*</span>
              </label>
              <br />
              <select
                className="form-select"
                name="leaveType"
                value={state.leaveType}
                onChange={handleChange}
                required
              >
                <option disabled hidden></option>
                {leaveOptions.map((leave) => {
                  return (
                    <option key={leave.value} value={leave.value}>
                      {leave.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-8">
              <div style={{ float: "right" }}>
                <CDBBtn
                  color="dark"
                  flat
                  className="CDBBtn"
                  onClick={raiseLeave}
                  disabled={isDisabled}
                  style={{ opacity: isDisabled ? "50%" : "100%" }}
                >
                  Raise Leave
                </CDBBtn>
              </div>
            </div>
          </div>
        </Card>
      )}
      <Card>
        <h4>Leave Records</h4>
        <div className="row mt-2">
          <div className="col-md-3">
            <label htmlFor="empName2">Employee Name</label>
            <br />
            <select
              name="empName2"
              className="form-select"
              value={state.empName2}
              onChange={handleChange}
              required
            >
              <option disabled hidden></option>
              {employees &&
                employees
                  .filter((el) => el.STATUS === "Active")
                  .map((emp) => {
                    return (
                      <option key={emp.EMP_CODE} value={emp.EMP_CODE}>
                        {emp.EMP_NAME}
                      </option>
                    );
                  })}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="startDt">
              Start Date<span className={classes.red}>*</span>
            </label>
            <br />
            <input
              name="startDt"
              type="date"
              className="form-control"
              value={state.startDt}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="endDt">
              End Date<span className={classes.red}>*</span>
            </label>
            <br />
            <input
              name="endDt"
              type="date"
              className="form-control"
              value={state.endDt}
              onChange={handleChange}
              min={minDate2}
              required
            />
          </div>
          <div className="col-md-3">
            <div style={{ float: "right" }}>
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <CDBBtn
                  color="dark"
                  flat
                  className="CDBBtn"
                  onClick={getLeavesByDate}
                  disabled={disableLeave}
                  style={{ opacity: disableLeave ? "50%" : "100%" }}
                >
                  Get Data
                </CDBBtn>
              </div>
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <CDBBtn
                  color="dark"
                  flat
                  className="CDBBtn"
                  onClick={resetFilter}
                >
                  Reset
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>

        <div
          id="leaveRecordsTable"
          style={{ display: "none", overflow: "auto" }}
        >
          <DataGrid
            className={classes.root}
            getRowId={(row) =>
              row.EMP_NAME + row.START_DATE + row.END_DATE + row.LEAVE_STATUS
            }
            {...leaves}
            rows={leaves}
            columns={columns}
            autoHeight
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            hideFooterSelectedRowCount
          />
        </div>
      </Card>
      {user?.role !== "Manager" && (
        <Card>
          <h4>Leave Balance Tacker</h4>
          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="empName2">Employee Name</label>
              <br />
              <select
                name="empName"
                className="form-select"
                value={balanceT.empName}
                onChange={handleBalanceChange}
                required
              >
                <option disabled hidden></option>
                {employees &&
                  employees
                    .filter((el) => el.STATUS === "Active")
                    .map((emp) => {
                      return (
                        <option key={emp.EMP_CODE} value={emp.EMP_CODE}>
                          {emp.EMP_NAME}
                        </option>
                      );
                    })}
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="startDt">
                Start Date<span className={classes.red}>*</span>
              </label>
              <br />
              <input
                name="startDate"
                type="date"
                className="form-control"
                value={balanceT.startDate}
                onChange={handleBalanceChange}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="endDt">
                End Date<span className={classes.red}>*</span>
              </label>
              <br />
              <input
                name="endDate"
                type="date"
                className="form-control"
                value={balanceT.endDate}
                onChange={handleBalanceChange}
                min={minDate2}
                required
              />
            </div>
            <div className="col-md-3">
              <div style={{ float: "right" }}>
                <div style={{ display: "inline-block", marginRight: "10px" }}>
                  <CDBBtn
                    color="dark"
                    flat
                    className="CDBBtn"
                    onClick={getLeavesBalanceByDate}
                    disabled={disableLeave}
                    style={{ opacity: disableLeave ? "50%" : "100%" }}
                  >
                    Get Data
                  </CDBBtn>
                </div>
                <div style={{ display: "inline-block", marginRight: "10px" }}>
                  <CDBBtn
                    color="dark"
                    flat
                    className="CDBBtn"
                    onClick={() =>
                      setBalanceT({ empName: "", startDate: "", endDate: "" })
                    }
                  >
                    Reset
                  </CDBBtn>
                </div>
              </div>
            </div>
          </div>

          <div
            id="leaveRecordsTableBalance"
            style={{ display: "none", overflow: "auto" }}
          >
            <DataGrid
              className={classes.root}
              getRowId={(row) =>
                row.EMP_NAME + row.START_DATE + row.END_DATE + row.LEAVE_STATUS
              }
              {...leavesBalance}
              rows={leavesBalance}
              columns={columnsBalance}
              autoHeight
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              pageSizeOptions={[10, 25, 50]}
              hideFooterSelectedRowCount
            />
          </div>
        </Card>
      )}

      <Modal isOpen={loading} className={classes.modal}>
        {/* <ModalBody className={classes.modalBody}> */}
        <div className={classes.modalContent}>
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </div>
  );
}

export default LeaveTabSA;
